.newsroom-container {
    margin: 20px;
}

.categories {
    display: block !important;
    position: relative;
    z-index: 3;
    width: 90%;
    margin: auto;
    padding-bottom: 5%;
}

.categories .slick-dots li button:before {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #1C2639;
}

.category-tab {
    padding: 10px 15px;
    text-align: center;
    color: #1C2639;
    transition: border-color 0.3s ease, color 0.3s ease;
}

.category-tab:hover,
.category-tab.active {
    border-bottom: 2px solid #1C2639;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.newsroom-hero h1 {
    font-size: 25px;
    font-family: 'NexaBlack';
    color: #fff;
}

.newsroom-hero p {
    font-size: 16px;
    font-family: 'Roboto';
    color: #fff;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 5%;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    gap: 1rem;
}


.card-logo {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center top;
    /* or just "center" */
    border-radius: 4px;
}

.cardPress {
    width: 100%;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    // height: fit-content;
}

.press-release-title {
    font-size: 20px;
    margin-bottom: 10px;
}

.card-body-p {
    font-size: 14px;
    margin-bottom: 5px;
    text-align: start;
}

.press-release-detail-main {
    display: flex;
    padding: 3% 5% 15% 5%;
    align-items: start;
    justify-content: center;
    gap: 2rem;
    width: 100%;

}

.press-release-detail-left {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    width: 70%;
}

.press-release-detail-left h1 {
    font-size: 32px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.read-press {
    color: #2a7ddf;
    cursor: pointer;
}

.read-press:hover {
    text-decoration: underline;
}

.press-date {
    font-size: 0.8em;
    font-weight: 700;
    text-transform: uppercase;
    color: #9ca3af;
}

.press-left-icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
}

.keywords-outer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.keywords-outer p {
    color: #979ba2;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin-bottom: 10px;
}

.press-keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.keyword-tag {
    background-color: #f0f4fa;
    color: #0b6fc3;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.keyword-tag:hover {
    background-color: #d9e4f2;
}

.detail-logo-press {
    width: 100%;
}

.press-release-detail-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.newsletter-grid-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 2rem;
}

.newsletter-grid-detail h1 {
    color: #374151;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
    letter-spacing: initial;
}

.card-body-detail {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.card-body-detail h3 {
    color: #1f2937;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.no-press-releases {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .press-release-detail-main {
        flex-direction: column;
        padding: 3% 5% 25% 5%;

    }

    .categories {
        padding-bottom: 15%;
    }

    .categories .slick-dots li button:before {
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #1C2639;
    }

    .press-release-detail-left,
    .press-release-detail-right {
        width: 100%;
    }
}