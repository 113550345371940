@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.getintouch {
    font-size: 25px;
    font-family: 'NexaBlack';
    color: #fff;
}

.contact-form input {
    background: #1C2639;
    border-bottom: 1px solid white;
    border-radius: 3px;
    padding: 8px 8px;
}

.contact-form textarea {
    background: #1C2639;
    border: 1px solid white;
    border-radius: 3px;
    padding: 8px 16px;
}

.contact-cards p {
    font-size: 15px;
    font-family: 'Montserrat';
    width: 90%;
    text-align: center;
}

@media (max-width: 1681px) {
    .contact-form-main {
        width: 40%;
    }
}

@media (max-width: 1367px) {
    .contact-form-main {
        width: 45%;
    }
}

@media (max-width: 1281px) {
    .contact-form-main {
        width: 48%;
    }
}

@media (max-width: 1025px) {
    .contact-main {
        padding: 0px 30px !important;
    }

    .contact-form-main {
        width: 100% !important;
    }

    .input-divs {
        flex-direction: column;
        gap: 1rem;
    }

    .contact-cards {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 601px) {
    .contact-main {
        padding: 0px 10px !important;
    }
}