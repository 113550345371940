.privacy-template h1 {
    font-size: 25px;
    font-family: 'NexaBlack';
}

.privacy-template h2 {
    font-size: 20px;
    font-family: 'NexaBlack';
}

.privacy-template h3 {
    font-size: 16px;
    font-family: 'NexaBlack';
}

.privacy-template p {
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 200;
}

@media (max-width: 1025px) {
    .privacy-template {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}