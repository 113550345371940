.whoWeAre-hero h1 {
    font-family: 'NexaBlack';
    font-size: 25px;
    color: #fff;
}

.aboutUpper-context h2 {
    font-size: 40px;
    font-family: 'NexaBlack';
}

.aboutUpper-context p {
    font-size: 18px;
    font-family: 'Roboto';
    width: 73%;
}

.whyus h2 {
    font-family: 'NexaBlack';
    font-size: 25px;
}

.whoarewe-cards,
.whoarewe-cards-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #ececec;
    padding: 20px;
    position: relative;
}

.whoarewe-cards h1,
.whoarewe-cards-right h1 {
    font-size: 40px;
    font-family: 'NexaBlack';
}

.whoarewe-cards p,
.whoarewe-cards-right p {
    font-size: 18px;
    font-family: 'Roboto';
}

.whoarewe-cards::before {
    content: "";
    position: absolute;
    background-image: url(../../Assets/pointer.svg);
    height: 30px;
    width: 20px;
    top: -1px;
    right: -4%;
    background-repeat: no-repeat;
}

.whoarewe-cards-right::before {
    content: "";
    position: absolute;
    background-image: url(../../Assets/pointersecond.svg);
    height: 30px;
    width: 20px;
    top: -1px;
    left: -4%;
    background-repeat: no-repeat;
}

.card h2 {
    font-size: 25px;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
}

.card p {
    font-size: 16px;
    font-family: 'Roboto';
    text-align: center;
}

.card i {
    font-size: 40px;
}

.whowearecards-mob {
    display: none;
}


@media (max-width: 1601px) {
    .line-c {
        height: 216px;
    }
}


@media (max-width: 1537px) {
    .line-b {
        height: 249px;
    }
}


@media (max-width: 1367px) {
    .whoweare-first {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .line-c {
        height: 250px;
    }
}

@media (max-width: 1281px) {
    .whatwedo-main {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media (max-width:1024px) {
    .whoweare-first {
        padding: 100px 30px 0px 30px !important;
        flex-direction: column;
        gap: 60px;
    }

    .aboutupper-context-main {
        width: 100% !important;
    }

    .aboutUpper-context p {
        width: 100%;
    }

    .aboutUpper-context h2 {
        font-size: 29px;
    }

    .whoarewe-cards p,
    .whoarewe-cards-right p {
        font-size: 14px;
    }

    .whoarewe-cards h1,
    .whoarewe-cards-right h1 {
        font-size: 27px;
    }

    .whowearecards-web {
        display: none !important;
    }

    .whowearecards-mob {
        display: flex !important;
        padding: 0px 30px;
        gap: 20px;
    }

    .whatwedo-main {
        padding: 0px 30px !important;
    }

    .whatwedo-main .card-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .whoarewe-cards-right::before {
        content: "";
        top: -1px;
        left: -2%;
    }

    .mob-line-a,
    .mob-line-b,
    .mob-line-c {
        height: 106px;
    }
}

@media (max-width: 915px) {

    .mob-line-a,
    .mob-line-b,
    .mob-line-c {
        height: 126px;
    }
}

@media (max-width: 541px) {
    .whoarewe-cards-right::before {
        content: "";
        top: -3px;
        left: -4%;
    }
}
