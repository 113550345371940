@tailwind base;
@tailwind components;
@tailwind utilities;

.herosec h1 {
    font-size: 55px;
    font-weight: 500;
    font-family: 'Montserrat';
    color: #fff;
    line-height: 55px;
    width: 75%;
}

.herosec p {
    font-size: 18px;
    font-weight: 100;
    font-family: "Poppins";
    color: #fff;
    width: 78%;
}

.herosec-img iframe {
    width: 100%;
    height: 318px;
}


@media (max-width: 1537px) {
    .herosec h1 {
        font-size: 50px;
        font-weight: 500;
        font-family: "Montserrat";
        color: #fff;
        line-height: 55px;
        width: 90%;
    }
}

@media (max-width: 1281px) {
    .herosec {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }
}

@media(max-width: 1025px) {
    .herosec {
        padding: 0 !important;
        flex-direction: column;
        gap: 20px;
    }

    .herosec h1 {
        font-size: 35px;
        line-height: 35px;
        width: 100% !important;
    }

    .herosec-text {
        width: 100% !important;
        padding-left: 30px;
        padding-top: 30px;

    }

    .herosec p {
        font-size: 16px;
        font-weight: 100;
        font-family: "Poppins";
        color: #fff;
        width: 90%;
    }

    .herosec-text button {
        padding: 10px 15px;
    }

    .herosec-img {
        width: 100% !important;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .herosec-img iframe {
        width: 90%;
        height: 318px;
    }
}