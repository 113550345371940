.blog-post {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.29);
    padding: 20px;
}

.blog-post h2 {
    font-family: 'NexaBlack';
    font-size: 25px;
    line-height: 28px;
}

.blog-post p {
    font-family: 'Montserrat';
    font-size: 12px;
}

.blogs-column h2 {
    font-size: 25px;
    font-family: 'NexaBlack';
}

.blogpost-single h2 {
    font-size: 29px;
    font-family: 'NexaBlack';
    text-align: center;
}

.blogpost-single h3 {
    font-size: 16px;
    font-family: 'NexaBlack';
}

.blogpost-single p {
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 200;
}

@media (max-width:1681px) {
    .blog-main {
        padding-top: 5rem;
        padding-bottom: 10rem;
    }

    .blog-post h2 {
        font-family: "NexaBlack";
        font-size: 22px;
        line-height: 25px;
    }
}


@media (max-width: 1367px) {
    .blog-main {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}


@media (max-width: 1025px) {
    .blogpost-single {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .blog-main {
        padding-left: 30px !important;
        padding-right: 30px !important;
        flex-direction: column;
    }

    .blog-grids {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        width: 100%;
    }

    .blogs-column {
        width: 100%;
    }
}
