.newsletter h1 {
    font-family: 'NexaBlack';
    font-size: 25px;
    color: #fff;
}

.newsletter p {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #fff;

}

@media (max-width: 1025px) {
    .newsletter-outer {
        top: -9%
    }

    .newsletter-main {
        flex-direction: column;
        padding: 40px 30px;
        width: 90% !important;
        align-items: center;
        gap: 20px;
    }

    .newsletter {
        align-items: center;
        width: 100% !important;
    }
}