@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.aboutus-context h2 {
    color: #1C2639;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 600;
}

.aboutus-context h1 {
    font-size: 40px;
    font-family: 'NexaBlack';
    font-weight: 200;
    width: 90%;
    line-height: 1;
}

.image-banner {
    bottom: 0;
    margin-left: 14%;
    width: 75%;
}

.image-banner h2 {
    font-size: 22px;
    font-family: 'NexaBlack';
    font-weight: 600;
}

.image-banner p {
    font-size: 16px;
    font-family: 'Poppins';
}

.aboutus-data h2 {
    font-size: 40px;
    font-family: 'NexaBlack';
    font-weight: 700;
    color: #fff;
}

.aboutus-data span {
    font-size: 18px;
    font-family: 'Roboto';
    color: #fff;
    text-align: center;
}

.news {
    font-size: 40px;
    font-family: 'NexaBlack';
}

.upper-logos-mob {
    display: none !important;
}


@media (max-width: 1601px) {
    .home-about-main {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
}

@media (max-width: 1537px) {
    .upper-logos-web {
        gap: 1rem !important;
    }
}

@media (max-width: 1441px) {
    .aboutus-context {
        gap: 2rem !important;
    }
}

@media(max-width: 1025px) {
    .upper-logos-web {
        display: none !important;
    }

    .upper-logos-mob {
        display: block !important;
        position: relative;
        z-index: 3;
        width: 32%;
        margin-left: 40%;
        margin-right: auto;
        padding-bottom: 5%;
    }

    .home-about-main {
        padding: 0 30px !important;
    }

    .home-about {
        flex-direction: column;
        padding: 0 !important;
    }

    .aboutus-img {
        width: 100% !important;
    }

    .image-banner {
        bottom: -10%;
        border-radius: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        margin-left: 2.5%;
        gap: 20px;
        padding: 4px 0px !important;
    }

    .image-banner-inner {
        align-self: center !important;
    }

    .home-about-inner {
        width: 55% !important;
        margin-bottom: 10%;
    }

    .image-banner-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .image-banner h2 {
        font-size: 18px;
        font-family: 'NexaBlack';
        font-weight: 600;
    }

    .image-banner p {
        font-size: 13px;
        text-align: center;
    }

    .trophy {
        width: 20% !important;
    }

    .aboutus-context {
        width: 100% !important;
        gap: 1rem !important;
    }

    .aboutus-context h1 {
        font-size: 29px;
        font-family: "NexaBlack";
        font-weight: 200;
        width: 100%;
        line-height: 26px;
    }

    .aboutus-context h2 {
        font-size: 15px;
    }

    .aboutus-context button {
        padding: 10px 15px !important;
    }

    .aboutus-data {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
        gap: 2rem !important;
    }

    .lower-logos {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
        place-items: center;
    }

    .lower-logo-container {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .news-media {
        padding-left: 0px !important;
        padding-right: 0px !important;

    }

}

@media (max-width: 769px) {
    .image-banner {
        bottom: -10%;
        border-radius: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        margin-left: 2.5%;
        gap: 20px;
        padding: 7px 19px !important;
    }
}

@media (max-width: 541px) {
    .home-about-inner {
        width: 75% !important;
        margin-bottom: 10%;
    }
}

@media (max-width: 481px) {
    .upper-logos-mob {
        display: block !important;
        position: relative;
        z-index: 3;
        width: 50%;
        margin-left: 32%;
        margin-right: auto;
        padding-bottom: 5%;
    }
}

@media (max-width: 413px) {
    .home-about-inner {
        width: 100% !important;
        margin-bottom: 10%;
    }
}