.pricing-hero h1 {
    font-family: 'NexaBlack';
    font-size: 25px;
    color: #fff;
    text-align: center;
}

.pricing-hero p {
    font-family: 'Roboto';
    font-size: 16px;
    color: #fff;
    text-align: center;
}

.pay-buttond button {
    font-size: 20px;
    font-family: 'NexaBlack';
    font-weight: 600;
}

.pricing-header h1 {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: 600;
}

.plan-header h3 {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 600;

}

.plan-header p {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 600;
}

.access-button {
    background: #1C2639;
    color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
}

.access-button-special {
    border: 1px solid white;
    padding: 9px 15px;
}

.getintouch h2 {
    font-size: 25px;
    font-family: 'NexaBlack';
    color: rgb(28 38 57);
}

.pricing-form input,
.pricing-form textarea {
    padding: 8px 16px;
    border: 1px solid #DDDDDD
}

.pricing-form-outer {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.pricing-form-outer .header {
    background-image: linear-gradient(236deg, #6EC1E4 0%, var(#1C2639) 100%);
}

.pricing-plans-mob {
    display: none !important;
}



@media (max-width:1681px) {}

@media (max-width: 1601px) {}

@media (max-width: 1585px) {}

@media (max-width: 1537px) {
    .pricing-header-inner {
        height: 164px;
    }
}

@media (max-width: 1441px) {}

@media (max-width: 1401px) {}

@media (max-width: 1381px) {}

@media (max-width: 1367px) {}

@media (max-width: 1281px) {}

@media (max-width: 1025px) {

    .pricing-header-inner {
        padding: 45px !important;
    }

    .pricing-plans-mob {
        display: flex !important;
    }

    .pricing-feature h2 {
        font-size: 15px;
    }

    .access-button {
        background: #1C2639;
        color: #fff;
        padding: 15px 15px;
        border-radius: 10px;
        font-size: 13px;
    }

    .pricing-header p {
        font-size: 16px;
    }

    .pricing-plans-web {
        display: none !important;
    }

    .tabs button {
        padding: 10px 30px;
        background: #DDDDDD;
    }

    .pricing-form-outer {
        width: 90% !important;
    }
}

@media (max-width: 915px) {}

@media (max-width: 901px) {}

@media (max-width: 885px) {}

@media (max-width: 858px) {}

@media (max-width: 840px) {}

@media (max-width: 821px) {}

@media (max-width: 811px) {}

@media (max-width: 801px) {}

@media (max-width: 769px) {

    .plan-header,
    .pricing-header-inner {
        height: 178px;
    }

    .pricing-header-inner {
        padding: 28px !important;
    }
}

@media (max-width: 713px) {}

@media (max-width: 641px) {}

@media (max-width: 616px) {}

@media (max-width: 601px) {}

@media (max-width: 541px) {}

@media (max-width: 501px) {

    .pricing-header p,
    .pricing-feature h2 {
        font-size: 13px;
    }

    .tabs button {
        padding: 10px 20px;
        background: #DDDDDD;
    }
}

@media (max-width: 481px) {
    .access-button {
        background: #1C2639;
        color: #fff;
        padding: 8px 15px;
        border-radius: 10px;
        font-size: 11px;
    }
}

@media (max-width: 449px) {}

@media (max-width: 431px) {}

@media (max-width: 415px) {}

@media (max-width: 413px) {}

@media (max-width: 394px) {

    .pricing-header p,
    .pricing-feature h2 {
        font-size: 11px;
    }

    .access-button {
        font-size: 8px;
    }
}

@media (max-width: 391px) {}

@media (max-width: 386px) {}

@media (max-width: 381px) {}

@media (max-width: 376px) {}

@media (max-width: 361px) {}

@media (max-width: 354px) {}

@media (max-width: 320px) {}

@media (max-width:280px) {}