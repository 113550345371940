.faq-para.visible {
    display: block;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.faq-para {
    display: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.faq-quest {
    transition: all 0.3s ease-in-out;
}

.FAQ-heading {
    font-size: 40px;
    font-family: 'NexaBlack';
    font-weight: 600;
}

.faq-quest h1 {
    font-size: 16px;
    font-family: 'NexaBlack';
    font-weight: 800;

}

@media (max-width:1681px) {}

@media (max-width: 1601px) {}

@media (max-width: 1585px) {}

@media (max-width: 1537px) {}

@media (max-width: 1441px) {
    .faq-main {
        padding-left: 9rem !important;
        padding-right: 9rem !important;
    }
}

@media (max-width: 1401px) {}

@media (max-width: 1381px) {}

@media (max-width: 1367px) {}

@media (max-width: 1281px) {}

@media (max-width: 1025px) {
    .faq-main {
        padding: 0px 30px !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .faq-upper {
        width: 100% !important;
    }

    .faq-lower {
        width: 50% !important;
    }

    .FAQ-heading {
        padding-top: 20px;
    }
}

@media (max-width: 915px) {}

@media (max-width: 901px) {}

@media (max-width: 885px) {}

@media (max-width: 858px) {}

@media (max-width: 840px) {}

@media (max-width: 821px) {}

@media (max-width: 811px) {}

@media (max-width: 801px) {}

@media (max-width: 769px) {}

@media (max-width: 713px) {}

@media (max-width: 641px) {}

@media (max-width: 616px) {}

@media (max-width: 601px) {
    .faq-lower {
        width: 70% !important;
    }
}

@media (max-width: 541px) {}

@media (max-width: 501px) {}

@media (max-width: 481px) {}

@media (max-width: 449px) {
    .faq-quest h1 {
        font-size: 14px !important;
        font-family: "NexaBlack";
        font-weight: 800;
    }

    .faq-lower {
        width: 100% !important;
    }
}

@media (max-width: 431px) {}

@media (max-width: 415px) {}

@media (max-width: 413px) {}

@media (max-width: 394px) {}

@media (max-width: 391px) {}

@media (max-width: 386px) {}

@media (max-width: 381px) {}

@media (max-width: 376px) {}

@media (max-width: 361px) {}

@media (max-width: 354px) {}

@media (max-width: 320px) {}

@media (max-width:280px) {}