@tailwind base;
@tailwind components;
@tailwind utilities;


.p-menubar {
    background: none;
    border: none;
    justify-content: space-between;
}

.p-menuitem-text {
    color: #fff;
}

.p-submenu-list .p-menuitem-text {
    color: #1C2639;
}

li.p-menuitem-content.p-focus .p-menuitem-text {
    color: #1C2639;
}

.p-menubar-button {
    display: none !important;
}

.dashboard-button {
    border-radius: 10px;
    border: 1px solid #fff;
}

.p-menuitem-content:hover {
    background: none !important;
    border: 1px solid #fff !important;
}

.p-menubar-root-list {
    gap: 5px;
}

li.p-menuitem.p-menuitem-active.p-highlight{
    background: none !important;
}

@media (max-width: 1281px) {
    .p-menubar-start {
        width: 40% !important;
    }
}

@media(max-width: 1024px) {
    .p-menubar-start {
        width: 30% !important;
    }

    .p-menuitem-list:active,
    .p-menuitem-list:hover {
        color: #1C2639 !important;
    }

    .p-submenu-list,
    .p-menubar-root-list {
        background: #1C2639;
    }

    .p-menuitem .p-menuitem-active .p-highlight,
    .p-menuitem .p-focus {
        color: #1C2639 !important;
    }

    .card {
        padding: 5px !important;
    }
}

@media (max-width: 961px) {
    .p-menubar-start {
        width: 50% !important;
    }

    .p-menubar-button {
        display: flex !important;
        width: 70px;
        height: 45px;
    }

}

@media (max-width: 601px) {
    .p-menubar-button {
        width: 50px;
    }
}