.footer a p,
.footer .text p {
    font-family: 'Roboto';
    font-size: 16px;
    color: #fff;
}

.footer h2 {
    font-family: 'NexaBlack';
    font-size: 25px;
    color: #fff;
}

.copyright h3 {
    font-family: 'Roboto';
    font-size: '16px';
    color: #fff;
    text-align: center;
}

.idrakai-footer img {
    width: 70%;
}

.footer-text {
    font-family: 'NexaBlack';
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

@media (max-width: 1025px) {
    .footer {
        padding: 0px 30px !important;
    }

    .footer-main {
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }

    .footer-main-upper {
        width: 100% !important;
    }

    .footer .text p {
        width: 100% !important;
    }

    .footer-lower-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media (max-width: 768px) {
    .newsletter-outer {
        top: -7% !important;
    }
}

@media(max-width: 394px) {
    .newsletter-main {
        flex-direction: column;
        padding: 40px 10px;
        width: 90% !important;
        align-items: center;
        gap: 20px;
    }
}

@media(max-width: 361px) {
    .newsletter p {
        font-size: 12px !important;
    }

    .newsletter input {
        width: 90% !important;
    }

    .footer-main-upper img {
        width: 65% !important;
    }
}