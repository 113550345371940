.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding: 10% 10% 20% 10%;
}

.notFound .notFound-404 {
    position: relative;
    height: 240px;
}

.notFound .notFound-404 h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0;
    color: #1C2639;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
}

.notFound .notFound-404 h1>span {
    text-shadow: -8px 0 0 #fff;
}

.notFound-404 h3 {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    margin: 0;
    letter-spacing: 3px;
    padding-left: 6px;
}

.notfound h2 {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0;
    margin-bottom: 25px;
}


@media (max-width: 1200px) {
    .notFound {
        padding: 10% 10% 30% 10%;
    }
}

@media (max-width: 768px) {
    .notFound .notFound-404 h1 {
        font-size: 150px;
        letter-spacing: -20px;
    }
}